<template>
  <v-col cols="12" md="6" lg="6" offset-lg="3" offset-md="3">
    <v-card
      class="elevation-1"
      :loading="loading"
    >
      <v-card-title>Payroll Computation</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            :value="activePayPeriod.end"
            outlined
            dense
            placeholder="Payroll Period"
            label="Payroll Period"
            readonly
          ></v-text-field>

          <v-col cols="12">
            <v-row>
              <v-btn
                color="primary"
                :disabled="loading"
                @click="showConfirmDialog=true">
                Process
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="activePayPeriod.processed_at!==null && activePayPeriod.closed_at===null"
                color="error"
                :disabled="loading"
                @click="showConfirmUndoProcessDialog=true">
                Undo last processed
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmDialog"
      @cancel="showConfirmDialog=false"
      @execute="submit">
    </confirm-password>

    <confirm-password
      :show="showConfirmUndoProcessDialog"
      @cancel="showConfirmUndoProcessDialog=false"
      @execute="undoProcess">
    </confirm-password>
  </v-col>
</template>

<script>
import { ref } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import Snackbar from '@/components/SnackBar.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'

export default {
  metaInfo: {
    title: 'Payroll Computation',
  },
  components: {
    Snackbar,
    ConfirmPassword,
  },
  setup() {
    const loading = ref(false)
    const activePayPeriod = ref({})
    const formLoading = ref(false)
    const error = ref('')
    const message = ref('')
    const messageStatus = ref('')
    const showConfirmDialog = ref(false)
    const showConfirmUndoProcessDialog = ref(false)

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      showConfirmDialog.value = false
      await store(
        `${process.env.VUE_APP_URI}/api/payroll/computation/process-payroll`,
        loading,
        { payrollPeriod: activePayPeriod.value.end },
        null,
      )
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value
    }

    const undoProcess = async () => {
      showConfirmUndoProcessDialog.value = false
      await store(
        `${process.env.VUE_APP_URI}/api/payroll/computation/undo-processed-payroll`,
        loading,
        { payrollPeriod: activePayPeriod.value.end },
        null,
      )
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value
    }

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayPeriod, formLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      }
    }

    getPayPeriod()

    return {
      store,
      submit,
      undoProcess,
      loading,
      message,
      messageStatus,
      activePayPeriod,
      showConfirmDialog,
      showConfirmUndoProcessDialog,
    }
  },
}
</script>
